const getAbsValues = (data) => {
    const firstPoint = data[data.length - 1][1];
    return data.map((element) => ([
        new Date(element[0]),
        ((firstPoint - element[1]) / firstPoint) * 100,
    ]));
};
const getDefaultGraphRange = (period) => {
    const currentDate = new Date(Date.now());
    let firstDate;
    switch (period) {
        case 180:
            firstDate = new Date(new Date().setDate(new Date(currentDate).getDate() - 180));
            break;
        case 90:
            firstDate = new Date(new Date().setDate(new Date(currentDate).getDate() - 90));
            break;
        case 30:
            firstDate = new Date(new Date().setDate(new Date(currentDate).getDate() - 30));
            break;
        case 7:
            firstDate = new Date(new Date().setDate(new Date(currentDate).getDate() - 7));
            break;
        default:
            firstDate = new Date(new Date().setDate(new Date(currentDate).getDate() - 365));
            break;
    }
    return [[currentDate, 0], [firstDate, 0]];
};
const getManagerAssetsValues = (data) => data.map((element) => ([new Date(element[0]), element[1]]));
const getMarginValues = (data) => data.map((element) => ([new Date(element[0]), element[1]]));
const getCopiersValues = (data) => data.map((element) => ([new Date(element[0]), element[1]]));
export { getAbsValues, getManagerAssetsValues, getMarginValues, getCopiersValues, getDefaultGraphRange, };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import RenderAsset from '@shared/ui/display/table-renderers/RenderAsset';
import RenderOrderSize from '@shared/ui/display/table-renderers/RenderOrderSize';
import RenderEntryPrice from '@shared/ui/display/table-renderers/RenderEntryPrice';
import RenderPnl from '@shared/ui/display/table-renderers/RenderPnl';
import RenderAssetDuration from '@shared/ui/display/table-renderers/RenderAssetDuration';
import tableRendererWrapper from '@shared/ui/display/Table/tableRendererWrapper';
import { useGetPortfolioCurrentPositionsQuery } from '@shared/api/portfolio';
import Table from '@shared/ui/display/Table';
import { IsDefined } from '@utils/js-ts';
import { ContainerColumn, SurfaceColumn } from '@src/components/styled';
import SkeletonGroup from '@shared/ui/progress-loaders/SkeletonGroup';
import PnlHeaderCell from '../PnlHeaderCell';
const MinHeight = 684;
const Container = styled(SurfaceColumn)(props => ({
    boxSizing: 'border-box',
    height: 'auto',
    padding: props.theme.spacing_sizes.l,
    // TODO: move shadow to theme
    boxShadow: '0px 1px 3px 0px #E6E8EB',
    alignItems: 'center',
    justifyContent: 'center',
}));
const LoaderContainer = styled(ContainerColumn)(() => ({
    flex: 1,
    minHeight: MinHeight,
    alignItems: 'center',
    justifyContent: 'center',
}));
const getColumnsDefinition = (t) => ([
    {
        header: t('table.current_positions.asset'),
        accessorKey: 'asset',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(RenderAsset),
    },
    {
        header: t('table.current_positions.order_size'),
        accessorKey: 'order_size',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(RenderOrderSize),
    },
    {
        header: t('table.current_positions.entry_price'),
        accessorKey: 'entry_price',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(RenderEntryPrice),
    },
    {
        header: PnlHeaderCell,
        accessorKey: 'pnl',
        enableSorting: true,
        meta: {
            flex: 1,
            align: 'right',
            headerAlign: 'right',
        },
        cell: tableRendererWrapper(RenderPnl),
    },
    {
        header: t('table.current_positions.duration'),
        accessorKey: 'opened',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(RenderAssetDuration),
    },
]);
const GetCurrentPositionsQueryTake = 10;
const CurrentPositionsTable = ({ className = undefined, portfolioId, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const columnsDefinition = useMemo(() => getColumnsDefinition(t), [t]);
    const [sortingState, setSortingState] = useState([]);
    const [paginationState, setPaginationState] = useState({
        pageIndex: 0,
        pageSize: GetCurrentPositionsQueryTake,
    });
    const skip = useMemo(() => paginationState.pageIndex * paginationState.pageSize, [paginationState]);
    const { data, isLoading, isFetching, isError, } = useGetPortfolioCurrentPositionsQuery({
        portfolioId,
        skip,
        take: GetCurrentPositionsQueryTake,
        sortByPnl: sortingState.length && sortingState[0].desc ? 'desc' : 'asc',
    });
    const isChunkEmpty = useMemo(() => !IsDefined(data) || !data.data.length, [data]);
    return (_jsxs(Container, { className: className, style: Object.assign({}, (isChunkEmpty && ({
            minHeight: MinHeight,
        }))), children: [!isFetching &&
                isError &&
                _jsx("p", { children: "error" }), !isError &&
                (isLoading || IsDefined(data)) && (_jsx(SkeletonGroup, { isLoading: isLoading, children: _jsx(Table, { data: (data === null || data === void 0 ? void 0 : data.data) || [], rowsCount: (data === null || data === void 0 ? void 0 : data.count) || 0, columnDef: columnsDefinition, paginationState: paginationState, onPaginationChange: setPaginationState, sortingState: sortingState, onSortingChange: setSortingState, isLoading: isLoading, hideHeader: isLoading || (IsDefined(data) && data.count === 0), defaultSkeletonRows: 10, skeletonStyles: {
                        margin: `${theme.spacing_sizes.xs}px 0px`,
                    } }) }))] }));
};
export default CurrentPositionsTable;
